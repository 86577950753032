import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import {
  getExternalHTTPClient,
  getHTTPClient,
  yieldFailureResponse,
  YieldResponse,
  yieldSuccessResponse,
} from '@core/http-client';
import { addressLookupActions } from '@redux/reducers/common/address-lookup/addressLookupReducer';
import {
  GetAddressDoctorRequestPayload,
  GetCoordsFromAddressRequest,
  GetCoordsFromAddressSuccessPayload,
  GetFinalAddressDoctorRequest,
  GetFinalAddressRequest,
  GetLoqateIndexPayload,
  GetLoqateIndexSubPayload,
} from '@redux/types/common/address-lookup/addressLookupTypes';
import { getQueriesAsSearch } from '@shared/utils/common';

const $http = getHTTPClient();
const $pcaHttp = getExternalHTTPClient('https://services.postcodeanywhere.co.uk/capture/Interactive');

export function* getAutocompleteAddressesPreview(action: PayloadAction<GetLoqateIndexPayload>) {
  const { payload, controller } = action.payload;
  const countriesQueryString = window.AS.user?.organisation?.country_search ? `&countries=${window.AS.user.organisation.country_search}` : '';


  try {
    yield put(addressLookupActions.setAddressLoading(true));
    const key: string = window.AS.pcaKey;

    const { data } = yield call(() =>
      $pcaHttp.get(`/Find/v1.00/json3.ws?container=&key=${key}&text=${payload}${countriesQueryString}`, {
        signal: controller.signal,
      })
    );

    if (data?.Items[0]?.Error) {
      yield put(addressLookupActions.setAutocompletePreviewAddressFailure());
    } else {
      yield put(addressLookupActions.setAutocompletePreviewAddress(data.Items));
    }
  } catch (e) {
    console.error(e);
  }
}

export function* getAutocompleteFinalAddress(action: PayloadAction<GetFinalAddressRequest['payload']>) {
  const {
    payload: { id },
    controller,
    resolve,
    reject,
  } = action.payload;

  yield put(addressLookupActions.setAddressLoading(true));

  const key: string = window.AS.pcaKey;

  const { error, response }: YieldResponse = yield call(() =>
    $pcaHttp
      .get<any>(`/Retrieve/v1.00/json3.ws?Field1Format={Latitude}&Field2Format={Longitude}&id=${id}&key=${key}`, {
        signal: controller.signal,
      })
      .then(yieldSuccessResponse)
      .catch(yieldFailureResponse)
  );

  if (response) {
    yield put(addressLookupActions.setAutocompleteFinalAddress(response.data.Items[0]));
    yield call(() => resolve());
  } else if (error) {
    console.error(error);
    yield call(() => reject(error));
  }
}

export function* getAutocompleteFullAddresses(action: PayloadAction<GetLoqateIndexSubPayload>) {
  const { payload, controller } = action.payload;

  try {
    yield put(addressLookupActions.setAddressLoading(true));

    const key: string = window.AS.pcaKey;

    const { data } = yield call(() =>
      $pcaHttp.get(`/Find/v1.00/json3.ws?container=${payload.id}&key=${key}&text=${payload.text}`, {
        signal: controller.signal,
      })
    );

    if (data.Items[0].Type === 'Address') {
      yield put(addressLookupActions.setAutocompleteFullPreviewAddress(data.Items));
    } else {
      yield put(addressLookupActions.setAutocompletePreviewAddress(data.Items));
    }
  } catch (e) {
    console.error(e);
  }
}

export function* getAddressDoctorPreviewSaga(action: PayloadAction<GetAddressDoctorRequestPayload>) {
  const { payload, controller } = action.payload;

  try {
    yield put(addressLookupActions.setAddressDoctorLoading(true));

    const { data } = yield call(() =>
      $http.get(`/address-doctor/search${getQueriesAsSearch(payload)}`, {
        signal: controller.signal,
      })
    );

    yield put(addressLookupActions.getAddressDoctorPreviewSuccess(data));
  } catch (e) {
    console.error(e?.response?.data?.message);
  }
}

export function* getAddressDoctorFullAddressSaga(action: PayloadAction<GetFinalAddressDoctorRequest>) {
  const { payload, controller, resolve, reject } = action.payload;

  yield put(addressLookupActions.setAddressDoctorLoading(true));

  const { error, response }: YieldResponse = yield call(() =>
    $http
      .get(`/address-doctor/lookup${getQueriesAsSearch(payload)}`, {
        signal: controller.signal,
      })
      .then(yieldSuccessResponse)
      .catch(yieldFailureResponse)
  );

  if (response) {
    yield put(addressLookupActions.getAddressDoctorFullAddressSuccess(response.data));
    yield call(() => resolve());
  } else if (error) {
    console.error(error);
    yield call(() => reject(error));
  }
}

const getCoordsFromAddress = (address: Address, deepSearch = true, resolve: any) => {
  const geocoder = new google.maps.Geocoder();

  const addressString = [address.name, address.line1, address.line2, address.town, address.postcode, address.country]
    .filter((line) => line)
    .join(', ');

  // console.log('geocode, looking up address: ', addressString);
  geocoder.geocode({ address: addressString }, (results, status) => {
    if (status == google.maps.GeocoderStatus.OK && results && results.length) {
      // Found a street view location
      // console.log('found address: ', results[0]);
      resolve(results[0]?.geometry.location.toJSON());
    } else {
      // Retry with less strict search
      if (deepSearch) {
        // Just use postcode
        // console.log('retrying with deep search');
        getCoordsFromAddress(
          {
            name: '',
            line1: '',
            line2: '',
            town: '',
            county: '',
            postcode: address.postcode,
            country: address.country || '',
            domestic_id: '',
          },
          false,
          resolve
        );
      } else {
        // console.log('could not find a location for the address: ', address);
        resolve(undefined);
      }
    }
  });
};

export function* getCoordsFromAddressSaga(action: PayloadAction<GetCoordsFromAddressRequest>) {
  yield put(addressLookupActions.setCoordsFromAddressLoading(true));

  // console.log('getCoordsFromAddressSaga. payload: ', action.payload);
  const coords: GetCoordsFromAddressSuccessPayload = yield call(
    () => new Promise((resolve) => getCoordsFromAddress(action.payload, true, resolve))
  );

  // console.log('getCoordsFromAddressSaga. coords: ', coords);
  yield put(addressLookupActions.getCoordsFromAddressSuccess(coords));
}
