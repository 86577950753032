import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { getHTTPClient, yieldFailureResponse, YieldResponse, yieldSuccessResponse } from '@core/http-client';
import { contactManageActions } from '@redux/reducers/crm/contacts/manage/contactManageReducer';
import {
  CreateContactRequestPayload,
  DeleteCrmContactPayload,
  GetCrmContactDetailsPayload,
  UpdateCrmContactDetailsPayload,
} from '@redux/types/crm/contacts/contactsTypes';
import { showNotification } from '@shared/components/notification';
import { getDefaultError } from '@shared/utils/common';

const $http = getHTTPClient();

export function* createContactsSaga(action: PayloadAction<CreateContactRequestPayload>) {
  try {
    yield put(contactManageActions.setCreateLoading(true));

    const { data } = yield call(() => $http.post(`/contacts`, action.payload.data));
    if (action.payload.callback) {
      action.payload.callback(data.id);
    }
    yield put(contactManageActions.createContactSuccess());
  } catch (e) {
    if (e?.response?.status == 422) {
      const errorMessages: ItemString = {};
      if (e?.response?.data?.errors) {
        Object.keys(e?.response?.data?.errors).forEach((item: string) => {
          if (Array.isArray(e?.response?.data?.errors[item])) {
            errorMessages[item] = e?.response?.data?.errors[item][0];
          } else {
            errorMessages[item] = e?.response?.data?.errors[item];
          }
        });
        yield put(contactManageActions.createContactFailure(errorMessages));
      }
    } else {
      showNotification(getDefaultError(e?.response?.data?.message), 'error');

      yield put(contactManageActions.createContactFailure({}));
    }
  }
}

export function* getContactDetailsSaga(action: PayloadAction<GetCrmContactDetailsPayload['payload']>) {
  const { payload, resolve, reject } = action.payload;

  yield put(contactManageActions.setDetailsLoading(true));

  const { error, response }: YieldResponse = yield call(() =>
    $http.get(`/contacts/${payload}`).then(yieldSuccessResponse).catch(yieldFailureResponse)
  );

  if (response) {
    yield put(contactManageActions.getDetailsSuccess(response.data));
    yield call(() => resolve());
  } else if (error) {
    const errorMessage = getDefaultError(error?.response?.data?.message);

    yield put(contactManageActions.getDetailsFailure(errorMessage));
    yield call(() => reject(error));

    showNotification(errorMessage, 'error');
  }
}

export function* deleteContactSaga(action: PayloadAction<DeleteCrmContactPayload['payload']>) {
  const { payload, resolve, reject } = action.payload;

  const { id, ...body } = payload;

  yield put(contactManageActions.setDeleteContactLoading(true));

  const { response, error }: YieldResponse = yield call(() =>
    $http.delete(`contacts/${id}`, { data: body }).then(yieldSuccessResponse).catch(yieldFailureResponse)
  );

  if (response) {
    yield put(contactManageActions.deleteContactSuccess());
    yield call(() => resolve());
  } else if (error) {
    yield put(contactManageActions.deleteContactFailure());
    yield call(() => reject(error));

    showNotification(getDefaultError(error?.response?.data?.message), 'error');
  }
}

export function* updateContactDetailsSaga(action: PayloadAction<UpdateCrmContactDetailsPayload['payload']>) {
  const {
    payload: { id, data },
    resolve,
    reject,
  } = action.payload;

  yield put(contactManageActions.setUpdateDetailsLoading(true));

  const { response, error }: YieldResponse = yield call(() =>
    $http
      .put(`/contacts/${id}`, { ...data })
      .then(yieldSuccessResponse)
      .catch(yieldFailureResponse)
  );

  if (response) {
    yield put(contactManageActions.updateDetailsSuccess(response.data));
    yield call(() => resolve(response.data));
  } else if (error) {
    if (error.response?.status == 422) {
      const errorMessages: ItemString = {};
      if (error.response?.data?.errors) {
        Object.keys(error.response?.data?.errors).forEach((item: string) => {
          if (Array.isArray(error.response?.data?.errors[item])) {
            errorMessages[item] = error.response?.data?.errors[item][0];
          } else {
            errorMessages[item] = error.response?.data?.errors[item];
          }
        });
        yield put(contactManageActions.updateDetailsFailure(errorMessages));
      }
    } else {
      showNotification(getDefaultError(error.response?.data?.message), 'error');

      yield put(contactManageActions.updateDetailsFailure({}));
    }

    yield call(() => reject(error));
  }
}
